var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-card", { attrs: { "body-class": "text-center" } }, [
    _c("img", {
      staticClass: "webmag-logo-big",
      attrs: {
        src: require("@/assets/logo-webmag-big.png"),
        alt: "webmag logo"
      }
    }),
    _c("h4", [_vm._v(_vm._s(_vm.$t("passwordReset.resetConfirmTitle")))]),
    _c("p", {
      domProps: { innerHTML: _vm._s(_vm.$t("passwordReset.resetConfirmMsg")) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }