<template>
  <b-card body-class="text-center">
    <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
    <h4>{{ $t('passwordReset.resetConfirmTitle') }}</h4>
    <p v-html="$t('passwordReset.resetConfirmMsg')"></p>
  </b-card>
</template>

<script>
import { Auth } from '@aws-amplify/auth';

export default {
  name: 'PasswordResetSuccessCard',
  async created() {
    await Auth.signOut({ global: true });
  },
};
</script>
